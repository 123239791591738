import * as React from "react";
import { Grid, Row, Col } from "rsuite";
import { Link } from "gatsby";
import LayoutPatient from "components/layout/patient";
import HeaderLarge from "components/section/headerLarge";
import SectionWrapper from "components/section/sectionWrapper";
import { StaticImage } from "gatsby-plugin-image";

const OverPage = () => {
  return (
    <LayoutPatient>
      <HeaderLarge
        title="Over het Kennisplatform"
        description="‘Elke patiënt, arts, tandarts of verpleegkundige weet de weg te vinden naar goede expertise over antistolling’."
      />

      <SectionWrapper padded background>
        <Grid>
          <Row style={{ marginBottom: "20px", fontSize: "18px" }}>
            <Col xs={18}>
              <h2 style={{ color: "#000", marginBottom: "20px" }}>
                Onze missie
              </h2>
              <p style={{ lineHeight: "2" }}>
                Met dit Kennisplatform willen we uitwisseling van kennis op alle
                terreinen van antistollingszorg bevorderen: Het beschikbaar
                stellen van actuele informatie betreffende de praktijk van
                antistollingszorg aan professionals, teneinde de efficiëntie en
                veiligheid van diagnostiek en behandeling te versterken.
                Indirect informatie betreffende antistollingszorg beschikbaar
                stellen voor patiënten en haar/zijn mantelzorgers.
              </p>
              <h3 style={{ color: "#000", marginTop: "40px" }}>
                Patiëntinformatie
              </h3>
              <p style={{ lineHeight: "2" }}>
                U vindt hier algemene informatie en veelgestelde vragen over het
                gebruik van antistollingsmiddelen. De Trombosestichting heeft de
                inhoud afgestemd op vragen die wij hebben ontvangen van
                gebruikers van antistollingsmedicatie.
              </p>
              <p style={{ lineHeight: "2" }}>
                Mist u informatie of vragen? U kunt ons{" "}
                <Link to="/patient/contact">hier</Link> een bericht sturen. We
                willen de patiëntinformatie zo compleet mogelijk maken.
              </p>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
      <SectionWrapper padded>
        <Grid>
          <Row style={{ fontSize: "18px" }}>
            <Col xs={24}>
              <p style={{ lineHeight: "2", marginBottom: "40px" }}>
                De patiëntinformatie is ontwikkeld naar een idee van de
                Federatie van Nederlandse Trombosediensten, de Cliëntenraad
                Trombosediensten CTD en de Trombosestichting.
              </p>
              <p style={{ lineHeight: "2" }}>
                <StaticImage
                  layout="fixed"
                  src="../../images/logo/logo-tsn.png"
                  alt="Logo Trombosestichting"
                  style={{
                    width: "150px",
                    height: "48px",
                    marginBottom: "10px",
                  }}
                />
              </p>
              <p style={{ lineHeight: "2" }}>
                Trombosestichting Nederland
                <br />
                Postbus 100
                <br />
                2250 AC VOORSCHOTEN
                <br />
                <a
                  href="https://www.trombosestichting.nl/?utm_source=kennisplatform-antistolling"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.trombosestichting.nl
                </a>
              </p>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </LayoutPatient>
  );
};

export default OverPage;
